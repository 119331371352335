<template>
  <transition :name="config.transition">
    <CCard :accent-color="status">
      <CCardHeader v-if="title">
        {{ $t(title) }}
        <div v-if="collapse" class="ml-4 card-header-actions">
          <CLink class="card-header-action btn-minimize" @click="isCollapsed = !isCollapsed">
            <CIcon :name="`cil-chevron-${isCollapsed ? 'bottom' : 'top'}`" />
          </CLink>
        </div>
        <slot class="float-right" name="append"></slot>
      </CCardHeader>
      <CCollapse :show="!isCollapsed" :duration="config.duration">
        <CCardBody>
          <slot />
        </CCardBody>
      </CCollapse>
    </CCard>
  </transition>
</template>

<script>
export default {
  name: 'ACard',
  props: {
    title: String,
    collapse: {
      type: Boolean,
      default: true
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    config: {
      type: Object,
      default: () => {
        return {
          duration: 400,
          transition: 'fade'
        }
      }
    },
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    status() {
      if (this.errors.length) return 'danger'
      return 'primary'
    }
  },
  data() {
    return {
      isCollapsed: this.collapsed || false
    }
  }
}
</script>
